import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed, ref } from 'vue'
import type { ShortGameType } from '@/types'
import CardContainer from '@/components_new/CardContainer.vue'
import Typography from '@/components_new/Typography.vue'
import BaseLink from '@/components_new/BaseLink.vue'

interface GameCardProps {
    game?: ShortGameType
    appearance: 'big' | 'medium'
    responsive?: boolean
    targetLink?: '_blank'
    lazy?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GameCard',
  props: {
    game: {},
    appearance: {},
    responsive: { type: Boolean },
    targetLink: {},
    lazy: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const isVideoAllowed = ref(false)
const gameLink = computed(() => `/game/${props.game?.hru}`)
const gameVideo = computed(() => props.game?.video)

function showVideo() {
    isVideoAllowed.value = true
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CardContainer, {
    is: props.game ? BaseLink : 'div',
    appearance: props.appearance,
    class: _normalizeClass(_ctx.$style.gameCard),
    alt: props.game?.title,
    src: props.game?.preview,
    responsive: props.responsive,
    to: gameLink.value,
    target: props.targetLink,
    lazy: props.lazy,
    itemscope: "",
    itemtype: "https://schema.org/VideoGame",
    itemprop: "url",
    onMouseenter: showVideo,
    onFocus: showVideo
  }, {
    default: _withCtx(() => [
      (isVideoAllowed.value && gameVideo.value)
        ? (_openBlock(), _createElementBlock("video", {
            key: 0,
            class: _normalizeClass(_ctx.$style.video),
            src: gameVideo.value,
            loop: "",
            muted: "",
            playsInline: "",
            autoplay: "",
            itemprop: "video"
          }, null, 10, _hoisted_1))
        : _createCommentVNode("", true),
      (props.game)
        ? (_openBlock(), _createBlock(Typography, {
            key: 1,
            size: "s",
            accent: "",
            responsive: false,
            itemprop: "name",
            class: _normalizeClass(_ctx.$style.title)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.game.title), 1)
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["is", "appearance", "class", "alt", "src", "responsive", "to", "target", "lazy"]))
}
}

})